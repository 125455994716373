import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
// import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import NextSteps from '../components/NextSteps'

const Product = ({ data: { prismicProduct } }) => {
  const { data } = prismicProduct
  return (
    <Layout>
      <Helmet
        title={`Charthouse | ${data.title.text}`}
        meta={[
          { name: 'description', content: data.introduction.text},
          { property: 'og:title', content: `Charthouse | ${data.title.text}` },
          { property: 'og:description', content: data.introduction.text },
          { name: 'twitter:title', content: `Chartouse | ${data.title.text}` },
          { name: 'twitter:description', content: data.introduction.text },
        ]}
      />
      <PageHeader 
        heading={data.title.text}
        introduction={data.introduction.html}
      />
      <PageContent content={data.content.html} />
      {data.next_steps && data.next_steps.length > 0 ?
        (<NextSteps
          steps={data.next_steps}
        />) : (
          <NextSteps
          steps={[
            {
              heading: { text: 'What to find out more about this product?'},
              button_label: { text: 'Contact us' },
              link: { url: '/contact' },
            } 
          ]}
        />
        )
      }
    </Layout>
  )
}

export default Product

export const pageQuery = graphql`
  query ProductBySlug($uid: String!) {
    prismicProduct(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        introduction {
          html
          text
        }
        content {
          html
        }
      }
    }
  }
`